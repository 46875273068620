.faq-section {
    & .title-meta {
        display: block;
        position: relative;
        top: 12px;
        font-family: 'Alex Brush', cursive;
        font-weight: 400;
        font-size: 30px;
        color: #BD945A;
        line-height: 1.5em;
        margin-bottom: 10px;
    }

    & .title {
        color: #000;
        font-family: 'Cormorant Garamond', serif;
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 1px;
    }

    & .accordion {
        --bs-border-width: 0;
        --bs-accordion-btn-focus-box-shadow: none;
        --bs-primary-bg-subtle: none;
        margin-top: 2rem;

        & .accordion-header {
            & button {
                color: #000;
                font-family: 'Cormorant Garamond', serif;
                margin-bottom: 0px;
                font-weight: 500;
                font-size: 30px;
                letter-spacing: 1px;

                &:after {
                    color: #BD945A;
                }
            }
        }
    }
}