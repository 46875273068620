.announce-section {
    & .logo {
        width: 6rem;
        margin: auto;
    }

    & .title {
        display: block;
        position: relative;
        top: 12px;
        font-family: 'Alex Brush', cursive;
        font-weight: 400;
        font-size: 60px;
        color: #BD945A;
        line-height: 1.5em;
        margin-bottom: 5px;

        @media screen and (max-width: 768px) {
            font-size: 32px;
            line-height: 1.2em;
            margin-bottom: 15px;
        }
    }

    & .subtitle {
        color: #000;
        font-family: 'Cormorant Garamond', serif;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 0;

        @media screen and (max-width: 768px) {
            font-size: 18px;
        }
    }
}