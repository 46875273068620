/* ======= Wedding Countdown style ======= */

.countdown-section {
    text-align: center;
    background-image: url('~/public/images/countdown/background.webp');
    background-position-x: center;

    & .section-head {
        margin-bottom: 15px;
        z-index: 1;

        & span {
            display: block;
            position: relative;
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 400;
            font-size: 18px;
            color: #fff;
            margin-bottom: 0;
        }

        & h4 {
            position: relative;
            color: #fff;
            font-family: 'Alex Brush', cursive;
            font-weight: 400;
            font-size: 60px;
            margin-bottom: 0;
        }

        & p {
            color: #FFF;
            font-size: 24px;
            margin-bottom: 0;
        }
    }

    & ul {
        margin-bottom: 0;
        padding: 0;

        & li {
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 400;
            display: inline-block;
            font-size: 15px;
            list-style-type: none;
            padding: 15px;
            color: #fff;
            line-height: 3em;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 2px;

            & span {
                display: block;
                font-family: 'Nunito Sans', sans-serif;
                font-size: 60px;
                font-weight: 700;
                color: #fff;
                letter-spacing: -1px;

                @media all and (max-width: 768px) {
                    font-size: 60px;
                }
            }

            @media all and (max-width: 768px) {
                font-size: 12px;
                padding: 15px;
            }
        }

        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }
    }
}