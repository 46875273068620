.story-section {
    padding-top: 100px;
    padding-bottom: 100px;
    margin-top: 1rem;
    margin-bottom: 1rem;

    & .story-img {
        max-width: 100%;
        position: relative;

        & .img {
            padding: 0 30px 30px 15px;
            position: relative;

            img {
                position: relative;
                z-index: 2;
            }

            &:before {
                content: '';
                position: absolute;
                top: 30px;
                right: 0;
                left: 45px;
                bottom: 0;
                border: 10px solid #BD945A;
            }

            &:after {
                content: '';
                width: 90%;
                position: absolute;
                top: -18%;
                bottom: -25%;
                left: 0;
                background-image: url('~/public/images/dots.png');
                background-repeat: repeat;
                z-index: -1;
            }
        }
    }

    & .story-subtitle {
        display: block;
        position: relative;
        top: 12px;
        font-family: 'Alex Brush', cursive;
        font-weight: 400;
        font-size: 30px;
        color: #BD945A;
        line-height: 1.25em;
        margin-bottom: 10px;
    }

    & .story-title {
        color: #000;
        font-family: 'Cormorant Garamond', serif;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 1px;
    }
}