.header {
    background-color: transparent;
    position: absolute;
    width: 100%;
    background: #0006;

    & .navbar-toggler {
        background-color: #BD945A;
        border-radius: 50%;
        margin: 1rem;
        padding: 1rem;
        color: #fff;
        border: none;

        &:focus {
            border: none;
            outline: 0;
            box-shadow: none;
        }
    }

    & &--brand {
        & .logo {
            width: 4rem;
        }
    }

    & &--nav {
        & .nav-link {
            color: white;
            font-size: 1rem;
            text-transform: uppercase;
        }
    }

    @media (max-width: 768px) {
        background-color: transparent;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;

        & &--brand {
            display: none;
        }

        & &--nav {
            display: none;
        }
    }

    & a.rspv {
        font-family: 'Nunito Sans', sans-serif;
        background: #BD945A;
        border: 1px solid #BD945A;
        color: #fff;
        padding: 15px 30px;
        margin: 0;
        font-weight: 400;
        position: relative;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2px;
        border-radius: 0px;

        &:active,
        &:focus-visible,
        &:disabled {
            background: #BD945A !important;
            border: 1px solid #BD945A !important;
        }

        &:hover {
            border: 1px solid #BD945A !important;
        }
    }
}

.offcanvas-menu {
    background-color: #F6F1F0;

    & .offcanvas-menu-body {
        text-align: center;

        & .stack {
            height: 100%;
        }

        & .logo-section {
            & .image {
                height: 75px;

                & img {
                    max-width: 100%;
                }
            }

            & .logo {
                margin-top: 4rem;
                font-size: 36px;
                font-weight: bold;
                color: #bfa06e;
                font-family: "Alex Brush", cursive;
                font-weight: 400;
                line-height: 1.5em;
            }

            & .date {
                font-family: 'Nunito Sans', sans-serif;
                font-weight: 400;
                text-transform: none;
                text-align: center;
                font-size: 10px;
                letter-spacing: 5px;
                color: #bfa06e;
                margin-bottom: 5px;
            }
        }
    }

    & .offcanvas-menu-container {
        margin-top: 2rem;
        flex-grow: 1;

        & ul {
            list-style-type: none;
            padding: 0;

            & li {
                margin: 3px 0 3px 0;
                padding: 0 0 3px 0;

                & a {
                    color: #000;
                    text-decoration: none;
                    position: relative;
                    padding: 0;
                    font-family: 'Cormorant Garamond', serif;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 1.5em;
                    letter-spacing: 0.5px;
                    -webkit-transition: 0.3s;
                    -o-transition: 0.3s;
                    transition: 0.3s;
                    border-bottom: 1px solid transparent;
                }
            }
        }
    }

    & .offcanvas-menu-footer {
        font-size: 12px;
        color: #888;
        letter-spacing: 0.5px;
    }

    .separator {
        width: 60px;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        border-bottom: 1px solid rgba(189, 148, 90, 0.4);
        margin: 5px auto;
    }
}