.rspv-section {
    background-image: url('~/public/images/banner-rspv.jpg');

    & .title-meta {
        display: block;
        position: relative;
        top: 12px;
        font-family: 'Alex Brush', cursive;
        font-weight: 400;
        font-size: 30px;
        color: #BD945A;
        line-height: 1.5em;
        margin-bottom: 10px;
    }

    & .title {
        color: #000;
        font-family: 'Cormorant Garamond', serif;
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 1px;
    }

    & button,
    & input,
    & optgroup,
    & select,
    & textarea {
        font-family: 'Nunito Sans', sans-serif;
    }

    & input[type="password"]:focus,
    & input[type="email"]:focus,
    & input[type="text"]:focus,
    & input[type="file"]:focus,
    & input[type="radio"]:focus,
    & input[type="checkbox"]:focus,
    & textarea:focus {
        outline: none;
    }

    & input[type="password"],
    & input[type="email"],
    & input[type="text"],
    & input[type="file"],
    & textarea {
        max-width: 100%;
        margin-bottom: 15px;
        padding: 5px 15px;
        height: auto;
        background-color: #fff;
        -webkit-box-shadow: none;
        box-shadow: none;
        display: block;
        width: 100%;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.75em;
        color: #000;
        background-image: none;
        border: 1px solid #ececec;
    }

    & input:focus,
    & textarea:focus {
        border-bottom-width: 2px;
        border-color: #000;
    }

    & input[type="submit"],
    & input[type="reset"],
    & input[type="button"],
    & button {
        font-family: 'Nunito Sans', sans-serif;
        background: #BD945A;
        border: 1px solid #BD945A;
        color: #fff;
        padding: 15px 30px;
        margin: 0;
        font-weight: 400;
        position: relative;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2px;
        border-radius: 0px;

        &:active,
        &:focus-visible,
        &:disabled {
            background: #BD945A !important;
            border: 1px solid #BD945A !important;
        }

        &:hover {
            border: 1px solid #BD945A !important;
        }
    }


    & input[type="submit"]:hover,
    & input[type="reset"]:hover,
    & input[type="button"]:hover,
    & button:hover {
        background-color: transparent;
        color: #000;
    }

    & select {
        padding: 10px;
        border-radius: 5px;
    }

    & input[type="radio"],
    & input[type="checkbox"] {
        display: inline;
    }

    & label {
        color: #777;
        transition: all .3s cubic-bezier(.64, .09, .08, 1);
        display: inline-block;
        -moz-appearance: none;
        display: inline-block;
        vertical-align: middle;
        font-family: 'Nunito Sans', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.75em;
        margin-bottom: 0;
    }

    & input[type=radio]:after,
    & input[type=radio]:before {
        content: '';
        position: absolute;
        transition: all .3s cubic-bezier(.64, .09, .08, 1)
    }

    & input[type=checkbox] {
        position: relative;
        cursor: pointer;
        margin: 5px 23px 15px 0px;
        ;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 12px;
    }

    & input[type=checkbox]:before {
        content: '';
        width: 18px;
        height: 18px;
        border: 1px solid #ececec;
        background: #fff;
        position: absolute;
        top: 0px;
        margin-left: -1px;
    }

    & input[type=checkbox]:after {
        font-family: 'themify';
        content: '\e64c';
        color: #BD945A;
        background: transparent;
        position: absolute;
        top: 1px;
        margin-left: -1px;
        left: 0;
        width: 18px;
        height: 18px;
        border: none;
        display: -webkit-inline-flex;
        display: -ms-inline-flexbox;
        display: inline-flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        opacity: 0;
        transition: opacity .3s cubic-bezier(.64, .09, .08, 1);
        will-change: opacity;
        font-size: 12px;
    }

    & input[type=checkbox]:checked:after {
        opacity: 1
    }

    & input[type=radio] {
        position: relative;
        cursor: pointer;
        margin: 5px 20px 15px 0px;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 10px;
    }

    & input[type=radio]:before {
        background-color: #fff;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #ccc;
        display: inline-block;
        top: 0px;
        left: -4px;
        background-image: radial-gradient(circle, #BD945A 50%, #fff 70%);
        background-size: 0;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        will-change: background-size;
        z-index: 2;
    }

    & input[type=radio]:after {
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%
    }

    & input[type=radio]:checked:before {
        background-size: 14px 14px
    }

    & .form-control {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background: transparent;
        border: none;
        border-bottom: 0px solid #e5e5e5;
        height: 54px;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        border-radius: 0;
        padding: 0;
        font-family: 'Cormorant Garamond', serif;
        font-size: 18px;
        line-height: 1.75em;
        font-weight: 400;

        &:active,
        &:focus {
            outline: none;
            -webkit-box-shadow: none;
            box-shadow: none;
            background-color: #fff;
            border: 1px solid #ececec;
        }
    }

    & textarea.form-control {
        border: 1px solid #ececec;
    }

    & .form-check {
        padding-left: 0;
    }

    & .form-check-input {

        &,
        &:focus,
        &:checked {
            border: none;
            background-color: none;
            border-color: none;
            box-shadow: none;
        }
    }

    & .confirmation {
        & .check {
            color: #BD945A;
            font-size: 3rem;
        }

        & .error {
            color: red;
            font-size: 3rem;
        }
    }
}