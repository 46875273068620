.gallery-section {
    & .title-meta {
        display: block;
        position: relative;
        top: 12px;
        font-family: 'Alex Brush', cursive;
        font-weight: 400;
        font-size: 30px;
        color: #BD945A;
        line-height: 1.5em;
        margin-bottom: 10px;
    }

    & .title {
        color: #000;
        font-family: 'Cormorant Garamond', serif;
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 1px;
    }

    & .custom-carousel-wrapper {
        overflow-x: hidden;
        position: relative;
        touch-action: pan-y;

        & .custom-carousel-inner {
            height: 75vh;
            max-height: 800px;
            display: flex;
            flex-direction: row;
            transition: transform 0.5s ease-in-out;

            & .custom-carousel-item {
                height: 100%;

                & img {
                    height: 100%;
                    width: auto;
                }
            }
        }

        & .custom-carousel-controls {
            position: absolute;
            height: 100%;
            top: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 2rem;

            & .custom-carousel-control-container {
                display: flex;
                align-content: center;
                flex-wrap: wrap;

                & button {
                    font-family: 'Nunito Sans', sans-serif;
                    background: #BD945A;
                    border: 1px solid #BD945A;
                    color: #fff;
                    padding: 0.75rem;
                    margin: 0;
                    font-weight: 400;
                    font-size: 1.5rem;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    border-radius: 50%;
                    display: flex;
                    justify-items: center;
                    align-items: center;

                    &:active,
                    &:focus-visible,
                    &:disabled {
                        background: #BD945A !important;
                        border: 1px solid #BD945A !important;
                    }

                    &:hover {
                        border: 1px solid #BD945A !important;
                        background-color: #BD945A;
                        color: #000;
                    }
                }
            }
        }
    }
}