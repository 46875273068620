.intro-container {
    width: 100%;
    height: 100vh;
    background: url('~/public/images/intro/desktop-intro.webp') no-repeat center center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;


    @media (max-width: 768px) {
        background: url('~/public/images/intro/phone-original.webp') no-repeat;
        background-size: cover;
        background-position: center;
        align-items: flex-start;
    }

    & img {
        width: 100%;
    }

    & .intro-content {
        width: 100%;
        text-align: center;
        color: white;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

        @media (max-width: 768px) {
            padding-top: 4rem;
            padding-bottom: 4rem;
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

    & .names {
        margin: 0;
        font-family: 'Alex Brush', cursive;
        font-weight: 400;
        font-size: 8rem;
        color: white;

        @media (max-width: 768px) {
            font-size: 5rem;
            flex-grow: 1;

            & span {
                display: block;
                margin: 0 auto;
                line-height: 0;
                font-size: 5rem;
            }
        }
    }

    & .text {
        align-self: top;

        & .date,
        & .save-the-date {
            font-size: 2em;
            font-weight: 400;
            color: white;
            margin: 0;
            text-shadow: #000 0px 0px 8px;
        }
    }

    & .mobile-logo {
        display: none;
        // position: absolute;
        // bottom: 2rem;
        width: 6rem;
        margin: auto;

        @media (max-width: 768px) {
            display: block;
        }
    }
}