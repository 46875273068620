.seeyou-section {
    background-image: url('~/public/images/seeyou/background.webp');
    background-position-y: center;
    background-position-x: center;

    & .section-head {
        & span {
            color: #fff;
            font-size: 30px;
        }

        & h4 {
            position: relative;
            color: #fff;
            font-family: 'Alex Brush', cursive;
            font-weight: 400;
            font-size: 60px;
            margin-bottom: 0;

            @media screen and (max-width: 768px) {
                font-size: 32px;
                margin-bottom: 5px;
                line-height: 1em;
            }
        }

        & h3 {
            display: block;
            position: relative;
            top: 12px;
            font-family: 'Nunito Sans', sans-serif;
            font-weight: 700;
            font-size: 50px;
            color: #fff;
            margin-bottom: 0;
            line-height: 1em;
        }

        @media screen and (max-width: 768px) {
            font-size: 32px;
        }
    }
}

// #seeyou {
//     .section-head {

//         span,
//         h4,
//         h3 {
//             color: #BD945A;
//         }
//     }
// }