.getting-there-section {
    & .title-meta {
        display: block;
        position: relative;
        top: 12px;
        font-family: 'Alex Brush', cursive;
        font-weight: 400;
        font-size: 30px;
        color: #BD945A;
        line-height: 1.5em;
        margin-bottom: 10px;
    }

    & .title {
        color: #000;
        font-family: 'Cormorant Garamond', serif;
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 1px;
    }


    & .carousel {
        & .carousel-indicators {
            bottom: -3rem;

            & button {
                background-color: #D6D6D6;
                width: 10px;
                height: 10px;
                border-radius: 50%;

                &.active {
                    background-color: #BD945A;
                }
            }
        }
    }

    & .custom-card-wrapper {
        height: 100%;
        display: flex;

        & .card {
            margin-bottom: 2rem;

            & .card-title h5 {
                font-size: 21px;
                margin-top: 10px;
                margin-bottom: 15px;
                text-transform: uppercase;
            }

            & .card-text {
                & p {
                    font-family: 'Nunito Sans', sans-serif;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 1.75em;
                    color: #888;
                    margin: 0 0 20px;
                }

                & i {
                    color: #BD945A;
                }
            }
        }
    }
}

#hotels.getting-there-section {
    padding-top: 1rem;
}