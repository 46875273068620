.box-section {
    padding-top: 100px;
    padding-bottom: 100px;

    & .title-meta {
        display: block;
        position: relative;
        top: 12px;
        font-family: 'Alex Brush', cursive;
        font-weight: 400;
        font-size: 30px;
        color: #BD945A;
        line-height: 1.5em;
        margin-bottom: 10px;
    }

    & .title {
        color: #000;
        font-family: 'Cormorant Garamond', serif;
        margin-bottom: 0px;
        font-weight: 500;
        font-size: 30px;
        letter-spacing: 1px;
    }

    & .box-container {
        background-image: url('~/public/images/whenwhere/background.webp');
        background-position-y: center;
        background-position-x: center;
        background-size: cover;

        & .item-box {
            padding: 60px 30px;
            min-height: 100%;
            background-color: #fff;
            border: 1px solid #F6F1F0;
            border-right: 0;

            &:hover {
                background-color: rgba(0, 0, 0, 0.6);
                color: #fff;

                & .box-title {
                    color: #fff;
                }
            }

            & .number {
                font-size: 75px;
                margin-bottom: 15px;
                color: transparent;
                -webkit-text-stroke: 1px #BD945A;

                & .n-title {
                    font-size: 2rem;
                    color: #BD945A;
                    -webkit-text-stroke: 0;
                }
            }



            & .location {
                color: #BD945A;
            }
        }
    }
}