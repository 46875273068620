.cormorant-garamond-light {
  font-family: "Cormorant Garamond", serif;
  font-weight: 300;
  font-style: normal;
}

.cormorant-garamond-regular {
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  font-style: normal;
}

.cormorant-garamond-medium {
  font-family: "Cormorant Garamond", serif;
  font-weight: 500;
  font-style: normal;
}

.cormorant-garamond-semibold {
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  font-style: normal;
}

.cormorant-garamond-bold {
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  font-style: normal;
}

.cormorant-garamond-light-italic {
  font-family: "Cormorant Garamond", serif;
  font-weight: 300;
  font-style: italic;
}

.cormorant-garamond-regular-italic {
  font-family: "Cormorant Garamond", serif;
  font-weight: 400;
  font-style: italic;
}

.cormorant-garamond-medium-italic {
  font-family: "Cormorant Garamond", serif;
  font-weight: 500;
  font-style: italic;
}

.cormorant-garamond-semibold-italic {
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  font-style: italic;
}

.cormorant-garamond-bold-italic {
  font-family: "Cormorant Garamond", serif;
  font-weight: 700;
  font-style: italic;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75em;
  color: #888;
}

img {
  width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-family: 'Cormorant Garamond', serif;
  font-weight: 500;
  line-height: 1.25em;
  margin: 0 0 20px 0;
}

/* ===== Utilities ===== */

.mb-30 {
  margin-bottom: 30px;
}

.bg-pink {
  background-color: #faf8f7;
}

.bg-fixed {
  background-attachment: fixed;

  @media screen and (max-width: 768px) {
    background-attachment: scroll;
  }
}

.bg-img {
  background-size: cover;
  background-repeat: no-repeat;
}

.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

.p-40 {
  padding: 20px;
}

/* ===== Selection ===== */
::-webkit-selection {
  color: #fff;
  background: rgba(189, 148, 90, 0.75);
}

::-moz-selection {
  color: #fff;
  background: rgba(189, 148, 90, 0.75);
}

::selection {
  color: #fff;
  background: rgba(189, 148, 90, 0.75);
}